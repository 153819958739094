.pagetop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    background-color: #f1f5f9;
}

.listings-title {
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 200px;
    font-size: 40px;
}

.content-section {
    display: flex;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.filter-section {
    width: 20%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-right: 20px;
    margin-bottom: 200px;
}

.job-listings-section {
    width: 50%;
    margin-left: 500px;
    margin-top: -560px;
    margin-bottom: 400px;
}

.job-list {
    list-style-type: none;
}

.job-item {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    transition: box-shadow 0.3s;
}

.job-item:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.job-icon {
    flex: 0 0 60px;
    font-size: 40px;
    margin-right: 400px;
    margin-left: -165px;
    align-items: flex;
}

.job-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.job-title {
    font-size: 20px;
    margin-bottom: -30px;
    margin-top: -40px;
}

.job-remote {
    font-size: 12px;
    display: flex;
    align-items: flex;
    margin-top: 20px;
    margin-bottom: -25px;
}

.job-requirements {
    font-size: 14px;
}

.job-actions {
    margin-top: 10px;
}

.apply-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px; 
    width: 100px;
    border-radius: 5px;
    cursor: pointer;
}


.apply-button:hover {
    background-color: #0056b3;
}

.job-date {
    font-size: 12px;
    color: gray;
    margin-left: 400px;
    margin-right: -250px;
    margin-top: 3px;
}


.no-jobs {
    text-align: center;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.tag {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    margin-right: 5px;
}

.tag button {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
}

.filter-section label {
    display: block;
    margin-bottom: 15px;
}

.filter-section input,
.filter-section select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error {
    color: red;
    text-align: center;
}

.loading {
    text-align: center;
}

h2 {
    margin-bottom: 10px;
}
