.footer {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f1f1f1;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 10px;
  }
  
  .footer-section h2 {
    margin-bottom: 10px;
  }
  
  .footer-section p {
    margin-bottom: 20px;
  }
  
  .social-media {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .subscribe-form {
    display: flex;
    flex-direction: column;
  }
  
  .subscribe-form input {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .subscribe-form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .subscribe-form button:hover {
    background-color: #0056b3;
  }
  