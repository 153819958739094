.contact-content {
    padding: 20px;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .contact-header p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .contact-methods {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
  }
  
  .contact-method {
    flex: 1;
    margin: 0 10px;
    text-align: center;
  }
  
  .contact-method h2 {
    margin-bottom: 10px;
  }
  
  .contact-method p {
    margin-bottom: 10px;
  }
  
  .contact-method a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .contact-method a:hover {
    text-decoration: underline;
  }
  
  .contact-message {
    text-align: center;
  }
  
  .contact-message h2 {
    margin-bottom: 10px;
  }
  
  .contact-message p {
    font-size: 1.2em;
  }
  