@font-face {
	font-family: 'themify';
	src:url('../fonts/themify.eot?-fvbane');


	src:url('../fonts/themify.eot?#iefix-fvbane') format('embedded-opentype'),
		url('../fonts/themify.woff?-fvbane') format('woff'),
		url('../fonts/themify.ttf?-fvbane') format('truetype'),
		url('../fonts/themify.svg?-fvbane#themify') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.ti-tablet:before {
	content: "\e609";
}
.ti-search:before {
	content: "\e610";
}
.ti-arrow-right:before {
	content: "\e628";
}
.ti-arrow-left:before {
	content: "\e629";
}
.ti-heart:before {
	content: "\e634";
}
.ti-angle-right:before {
	content: "\e649";
}
.ti-angle-left:before {
	content: "\e64a";
}
.ti-arrow-top-right:before {
	content: "\e65a";
}
.ti-arrow-top-left:before {
	content: "\e65b";
}
.ti-home:before {
	content: "\e69b";
}
.ti-email:before {
	content: "\e75a";
}
