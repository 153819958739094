.thumb {
    width: 100px;
    height: 100px;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.thumb img {
    width: 100%;
    height: auto;
}

.location {
    margin-left: 10px;
}

.single_wrap ul, p {
    text-align: left;
    list-style-position: inside;
    padding-left: 0;
}
